import React from 'react';
import { CellMeasurer, CellMeasurerCache, List, WindowScroller } from 'react-virtualized';
import { useTranslation } from 'next-i18next';
import { FlexWrap } from '@components/wrapper';
import { LanguageCode } from 'src/constants';
import { ProductCardsWithBrandQuery } from 'src/generated/graphql';
import InfinityScrollLoader from '@components/InfinityScrollLoader';
import { Card } from '@components/common';
import useExchange from '@hooks/useExchange';
import { PATH_PRODUCT } from 'src/routes/paths';
import { discountAmount } from '@lib/price';
import { ICardClickProps } from '../Card/type';
import ProductsSkeleton from './ProductsSkeleton';

type Props = {
  products: ProductCardsWithBrandQuery['productCards'];
  filterCount: number;
  isLoading: boolean;
  cache: CellMeasurerCache;
  isVirtual?: boolean;
  handleCardClick?: (props: ICardClickProps, order: number) => void;
  nextOffset?: () => void | null;
};

export default function Products({ products, filterCount, isLoading, cache, isVirtual = true, handleCardClick, nextOffset = null }: Props) {
  const { i18n } = useTranslation();
  const { isReady } = useExchange(i18n.language as LanguageCode);
  /* eslint-disable @typescript-eslint/no-unused-vars */

  const convertProductInfo = (productInfo) => {
    const item = { ...productInfo };
    item.subImageUrl = productInfo.mainImageUrl;
    item.imageUrl = productInfo.subImageUrl;
    item.isGoods = true;
    item.brandName = productInfo.brand.name;
    item.brandCd = productInfo.brand.code;
    item.goodsNo = productInfo.id.toString();
    item.languageCode = i18n.language as LanguageCode;
    item.productViewUrl = PATH_PRODUCT.view(item.goodsNo);
    item.price = productInfo.salePrice;
    item.discountPercentage = discountAmount(productInfo.price, productInfo.salePrice);
    item.badgeName = productInfo.badgeNames[0] || '';
    item.isPromotion = Boolean(productInfo?.promotionEndDate);

    return item;
  };

  const rowRenderer = ({ index, key, style, parent }) => {
    const startIndex = index * 3;
    const productRow = (products || []).slice(startIndex, startIndex + 3);

    return (
      <CellMeasurer key={key} rowIndex={index} cache={cache} columnIndex={0} parent={parent}>
        {({ registerChild }) => {
          return (
            <div className={'flex justify-start relative pb-12 gap-x-9'} key={key} style={style} ref={registerChild}>
              {productRow.map((goodsInfo: any, idx: number) => {
                if (goodsInfo) {
                  return (
                    <Card
                      {...convertProductInfo(goodsInfo)}
                      key={startIndex + idx}
                      index={startIndex + idx}
                      onClick={(product) => {
                        handleCardClick && handleCardClick(product, startIndex + idx + 1);
                      }}
                    />
                  );
                }
                return <div key={idx} />;
              })}
            </div>
          );
        }}
      </CellMeasurer>
    );
  };

  const handleScroll = () => {
    if (filterCount !== products.length) nextOffset();
  };

  // hooni) example code
  if (!isReady) return <div></div>;

  return (
    <div>
      {isVirtual ? (
        <FlexWrap>
          <WindowScroller>
            {({ height, scrollTop }) => {
              return (
                <List
                  autoHeight
                  width={900}
                  height={height}
                  rowCount={Math.ceil((products || []).length / 3)}
                  rowHeight={cache.rowHeight}
                  rowRenderer={rowRenderer}
                  overscanRowCount={5}
                  estimatedRowSize={400}
                  scrollTop={scrollTop}
                />
              );
            }}
          </WindowScroller>
        </FlexWrap>
      ) : (
        <FlexWrap className={'gap-x-9'}>
          {(products || []).map((x, i) => {
            return <Card {...convertProductInfo(x)} key={i} index={i} />;
          })}
        </FlexWrap>
      )}

      {nextOffset && !isLoading && products && products.length > 0 && filterCount !== products.length && (
        <div style={{ position: 'relative', top: '-350px' }}>
          <InfinityScrollLoader action={handleScroll} />
        </div>
      )}

      {isLoading && <ProductsSkeleton />}
    </div>
  );
}
